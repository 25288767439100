<template>
  <ul>
    <menu-options-item icon="folder-open" close label="Abrir" @click="$emit('abrir')" />
    <div class="diviser"></div>
    <menu-options-item v-if="u.active" close icon="user-slash" icon-type="fa" label="Desativar" @click="$emit('desactive')" />
    <menu-options-item v-else close icon="user" icon-type="fa" label="Reativar" @click="$emit('active')" />
    <menu-options-item close icon="remove" label="Excluir" @click="$emit('excluir')"  label-class="text-negative" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
export default {
  name: 'list-menu',
  props: ['u'],
  components: {MenuOptionsItem}
}
</script>
