import UserMixin from "components/usuario/components/include/UserMixin"

export default {
  name: 'UsuarioMixin',
  mixins: [UserMixin],
  inject: {
    erplayout: {
      default () {
        //console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  mounted () {
    this.erplayout && this.erplayout.erpheader.menu.setActiveMenu('configuracoes')
  },
  methods: {
  }
}
