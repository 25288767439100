<script>
import {list, update as updateUser, deleteUser} from '@/domain/usuario/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage} from 'uloc-vue'
import {EBtnTableOptions, ErpSelect} from '@/plugins/uloc-erp'
import MenuOptions from '../../../layout/context-menu/context-window-options.vue'
import {ErpInput, ErpSField} from '@/plugins/uloc-erp'
import DefaultMixin from '../../mixin'
import listOptions from '../window/listOptions'
import {datePtToEn} from '@/utils/date'
import tableColumnsCache from "@/utils/tableColumnsCache"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import ListMenu from "components/usuario/components/include/list-menu.vue"
import {donwloadFile} from "@/utils/downloadFile"
import EBtn from "@/plugins/uloc-erp/components/button/EBtn"
import filters from "@/domain/usuario/helpers/filters"
import {usersState} from "components/usuario/menu"
import {roles} from "@/domain/usuario/helpers/roles"

const listName = 'usuarios.lista'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
  {label: 'Nome', name: 'name', active: true, sortable: true, ordering: 1},
  {label: 'ID Pessoa', name: 'idPerson', active: false, sortable: true, ordering: 1},
  {label: 'Documento', name: 'document', active: false, sortable: true, ordering: 1},
  {label: 'Usuário', name: 'username', active: true, sortable: true, ordering: 1},
  {label: 'Email', name: 'email', active: true, sortable: true, ordering: 1},
  {label: 'Papéis', name: 'papers', active: true, sortable: true, ordering: 1},
  {label: 'Perfil de Acesso', name: 'roles', active: true, sortable: true, ordering: 1},
  {label: 'Habilitado', name: 'status', active: true, sortable: true, ordering: 1},
], 1)

export default {
  name: 'Usuarios',
  provide: function () {
    return {
      container: this
    }
  },
  props: {
    hideTabs: {
      type: Boolean,
      default: false
    }
  },
  mixins: [DefaultMixin],
  components: {
    ErpSelect,
    EBtn,
    ListMenu,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    // ErpSelect,
    ErpSField,
    // ErpSelect,
    // UPopover,
    // UTooltip
  },
  data() {
    const filtros = JSON.parse(JSON.stringify(filters))
    return {
      listStorage: listStorage,
      listType: 'a',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 50
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    filterRolesOpts () {
      const erpRoles = Object.keys(roles).map(r => {
        return {label: roles[r], value: r}
      })
      return [
          ...erpRoles,
        {label: 'Todos', value: null}
      ]
    }
  },
  beforeMount () {
    if (this.$route.hash) {
      this.parseHash()
    }
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        if (this.parseHash()) {
          this.load()
        }
      })
    }
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    parseHash () {
      if (this.$route.hash) {
        const hash = this.$route.hash.replace('#', '')
        if (hash === 'inativos') {
          this.listType = 'i'
          this.table.filters.active = false
          //this.load()
        }
        if (hash === 'ativos') {
          this.table.filters.active = false
          this.listType = 'a'
          //this.load()
        }
        return true
      }
    },
    request({pagination, filter}, exportar = null) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active !== null && extraFilters.push(`&active=${this.table.filters.active ? 1 : 0}`)
      console.log('XXX', this.table.filters.search)
      this.table.filters.search && extraFilters.push(`&search=${encodeURIComponent(this.table.filters.search)}`)
      this.table.filters.role && extraFilters.push(`&role=${this.table.filters.role}`)
      // extraFilters.push(`&role=ROLE_ERP`)

      /*if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }*/

      //this.table.filters.pessoa && extraFilters.push(`&pessoa=${this.table.filters.pessoa.id || this.table.filters.pessoa}`)

      if (exportar) {
        extraFilters.push(`&export=${exportar}`)
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = `&sortBy=${pagination.sortBy || 'name'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&${extraFilters.join('')}`
      filtros = filtros.replace('+', '____XX____')
      filtros = encodeURI(filtros)
      filtros = filtros.replace('____XX____', '+')

      list(pagination.rowsPerPage, pagination.page, filtros, exportar)
          .then((response) => {
            if (exportar) {
              donwloadFile(response)
              this.table.loading = false
              return
            }
            this.table.serverPagination = pagination
            usersState.count = this.table.serverPagination.rowsNumber = response.data.total
            this.table.serverData = response.data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
            this.table.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      this.$router.push({name: 'usuarios.usuario', params: {id: id}})
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir usuario-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteUser(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Usuário excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(listName, newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
      if (v === 'todos') {
        this.table.filters.active = null
      } else if (v === 'a') {
        this.table.filters.active = 1
      } else if (v === 'i') {
        this.table.filters.active = 0
      } else {}
      this.load()
    },
    label (a, b) {
      /*if (this.listType === b) {
        return a + ` (${this.table.serverPagination.rowsNumber})`
      }*/
      return a
    },
    exportar (tipo = 'excel') {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      }, tipo)
    },
    create() {
      this.$router.push({name: 'usuarios.usuario.new'})
    },
    activate (u, active = true) {
      this.$uloc.dialog({
        title: active ? 'Reativar usuário' : 'Desativar usuário',
        message: active ? 'Você tem certeza que deseja ativar este usuário? Ele voltará a acessar com o login e senha de acordo seu perfil de acesso' : 'Confirmar desativação do usuário? Ele não conseguirá acessar mais.',
        ok: 'Sim',
        cancel: 'Cancelar'
      }).then(() => {
        this.$uloc.loading.show()
        updateUser(u.id, {active: active}, false, 'PATCH')
            .then(response => {
              this.load()
              this.$uloc.loading.hide()
            })
            .catch(error => {
              this.alertApiError(error)
              this.$uloc.loading.hide()
            })
      }).catch(() => {})
    }
  },
  meta: {
    title: 'Usuários Internos',
    name: 'Usuarios'
  }
}
</script>

<template>
    <div class="v2-container wrapper-md">
<!--      <div class="text-right">
        <h6 class="font-16 font-bold m-b-lg">Usuários</h6>
      </div>-->
      <div class="erp-list list-tasks no-pad" v-if="$route.name === 'usuarios.list'">
        <div class="erp-list-top-btns items-end">
          <div v-if="!hideTabs" class="actives-btns col-grow-1">
            <u-btn @click="changeListType('a')" :label="label('Ativos', 'a')" class="active-table-btn" :class="{active: listType === 'a'}" no-caps />
            <u-btn @click="changeListType('i')" :label="label('Desativados', 'i')" class="active-table-btn" :class="{active: listType === 'i'}" no-caps />
          </div>
          <div class="col-grow-1 flex justify-end m-b">
            <u-btn @click="create" no-caps color="green"><i class="fa-duotone fa-fw fa-user m-r"></i> Criar Novo Usuário</u-btn>
          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table erp-table-options table-v2"
            :rows-per-page-options="[20, 50, 100, 500]"
            :hide-no-results-label="true"
        >
          <template slot="top" slot-scope="props">
            <div class="full-width">
            <e-row class="e-input-modern size1">
              <e-col style="min-width: 150px" class="m-l m-r">
                <erp-s-field
                    view="ll"
                    label="Buscar"
                >
                  <erp-input shortkey="F3" v-model="table.filters.search" @keydown.enter="pesquisar"/>
                </erp-s-field>
              </e-col>
              <e-col style="min-width: 150px" class="m-l m-r">
                <erp-s-field
                    view="ll"
                    label="Tipo"
                >
                  <erp-select v-model="table.filters.role" :options="filterRolesOpts"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 100px"><e-btn @click="pesquisar" label="Filtrar" /></e-col>
              <e-col class="flex items-end content-end justify-end">
<!--                <filtros-adicionais :filters="table.filters" :stats="stats" />-->
              </e-col>
            </e-row>
            <e-row>
            </e-row>
            </div>
          </template>
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              <template>
               {{ col.label }}
              </template>
              <div v-if="col.name === 'options'" class="text-center">
                <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
              </div>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs"/>
            </u-td>
            <u-td style="max-width: 40px; min-width: 40px; width: 40px;" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td key="name" :props="props">
              <span v-if="props.row.person">{{ props.row.person.name }}</span><span v-else>-</span>
            </u-td>
            <u-td key="idPerson" :props="props">
              <span v-if="props.row.person">{{ props.row.person.id }}</span><span v-else>-</span>
            </u-td>
            <u-td key="document" :props="props">
              <span v-if="props.row.person && props.row.person.document">{{ props.row.person.document|formataCpfCnpj2 }}</span><span v-else>-</span>
            </u-td>
            <u-td key="username" :props="props">
              {{ props.row.username }}
            </u-td>
            <u-td key="email" :props="props">
              {{ props.row.email }}
            </u-td>
            <u-td key="papers" :props="props">
              <div v-if="props.row.roles && props.row.roles">{{getPapers(props.row.roles)}}</div>
            </u-td>
            <u-td key="roles" :props="props">
              <div v-if="props.row.defaultGroup">{{ props.row.defaultGroup.name }}</div>
              <div v-if="props.row.roles && props.row.roles.includes('ROLE_ADMIN')">Administrador</div>
            </u-td>
            <u-td style="max-width: 100px; min-width: 100px; width: 100px" key="status" :props="props">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <rect id="UsuarioStatus" data-name="UsuarioStatus" width="12" height="12" rx="6" :fill="props.row.active ? '#3baf55' : '#EB0000'"/>
              </svg>
              <!--<span v-if="props.row.active">Ativo</span>
              <span v-else>Desativado</span>-->
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <list-menu
                      @abrir="abrir(props.row.id)"
                      @edit="abrir(props.row.id)"
                      @excluir="excluir(props.row.id)"
                      @desactive="activate(props.row, false)"
                      @active="activate(props.row, true)"
                      :u="props.row"
                  />
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>

      </div>
      <router-view />
    </div>
</template>
